<template>
    <HeaderComponent></HeaderComponent>
    <MenuBarComponent></MenuBarComponent>
    <div id="background-area">
        <div class="contents-area">
            <div id="side-menu-area">
                <BlogSideMenuComponent
                    v-bind:categories="categories"
                    v-bind:subcategories="subcategories"
                    v-bind:yearmonths="yearmonths"
                >
                </BlogSideMenuComponent>
            </div>
            <div id="articles-area">
                <div
                    v-for="article in articles"
                    class="content-area link"
                    v-on:click="moveToArticle(article.id)"
                >
                    <div class="article-area">
                        <div class="article-top-area">
                            <div class="article-title-area">
                                <div class="article-title-text">
                                    {{article.title}}
                                </div>
                            </div>
                        </div>
                        <div class="article-bottom-area">
                            <div class="cateory-name-area">
                                <div class="cateory-name-text">
                                    {{getCategoryName(article.subcategory_id)}} {{getSubcategoryName(article.subcategory_id)}}
                                </div>
                            </div>
                            <div class="article-created-date-area">
                                <div class="article-created-date-text">
                                    {{getDateString(article.created_time)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="prev-next-articles-link-area">
                    <div
                        v-if="!isFirstPage()"
                        id="prev-articles-link-area"
                        class="link"
                        v-on:click="moveToPrevArticles()"
                    >
                        <div id="prev-articles-link-text">
                            &lt;&lt;前へ
                        </div>
                    </div>
                    <div
                        v-if="!isLastPage()"
                        id="next-articles-link-area"
                        class="link"
                        v-on:click="moveToNextArticles()"
                    >
                        <div id="next-articles-link-text">
                            次へ&gt;&gt;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterComponent></FooterComponent>
</template>

<script>
    import { ref, onMounted } from "vue";
    import axios from "axios";

    import HeaderComponent from "@/components/header.vue";
    import MenuBarComponent from "@/components/menuBar.vue";
    import FooterComponent from "@/components/footer.vue";
    import BlogSideMenuComponent from "@/components/blog/sideMenu.vue";

    import router from "@/router/index.js";
    import httpUtility from "@/js/common/httpUtility.js";
    import datetimeUtility from "@/js/common/datetimeUtility.js";
    import objectUtility from "@/js/common/objectUtility.js";

    import blogConfig from "@/configs/blog/config.js";
    import blogPathConfig from "@/configs/blog/pathConfig.js";

    export default {
        name: "BlogView",
        components: {
            HeaderComponent,
            MenuBarComponent,
            FooterComponent,
            BlogSideMenuComponent
        },
        setup() {
            // -----------------------------------------
            // 定数
            // -----------------------------------------

            // -----------------------------------------
            // 変数
            // -----------------------------------------
            let articles = ref([]);
            let categories = ref([]);
            let subcategories = ref([]);
            let yearmonths = ref([]);
            let url_query_params = null;
            let current_page = null;
            let filtered_article_count = null;

            // -----------------------------------------
            // 画面読み込み時処理
            // -----------------------------------------
            onMounted(() => {
                url_query_params = new URLSearchParams(window.location.search);
                current_page = url_query_params.has("page") ? Number(url_query_params.get("page")) : 1;  // pageが存在しない場合は1ページ目

                getArticles();
                getYearmonths();
                getCategories();
                getSubcategories();
            })

            //-----------------------------------------
            // メソッド（APIコールなし・テンプレート内使用なし）
            //-----------------------------------------
            function getMaxPage(article_count) {
                // 記事数が0の場合はページ数1
                let max_page = 1;
                if (article_count > 0) {
                    max_page = Math.floor((article_count - 1) / blogConfig.DISPLAY_ARTICLE_NUM) + 1;
                }
                return max_page;
            }

            function sortBySort(objects) {
                // sort昇順にソート
                objects.sort((a, b) => {
                    let compare_result = a.sort - b.sort;
                    if (!a.sort) compare_result = -1;
                    return compare_result;
                })
            }

            function sortByCreatedTime(objects) {
                // created_time降順にソート
                objects.sort((a, b) => {
                    return new Date(b.created_time) - new Date(a.created_time);
                })
            }

            function sortByYearmonth(objects) {
                // year > monthの優先度で降順にソート
                objects.sort((a, b) => {
                    let compare_result = b.year - a.year;
                    if (compare_result === 0) compare_result = b.month - a.month;
                    return compare_result;
                })
            }

            //-----------------------------------------
            // メソッド（APIコールなし・テンプレート内使用あり）
            //-----------------------------------------
            function moveToArticle(article_id) {
                const path_params = {
                    article_id: article_id
                };
                router.push({name: "blog_article", params: path_params});
            }

            function moveToPrevArticles() {
                const query_params = {
                    page: current_page - 1,
                    category_id: url_query_params.get("category_id"),
                    subcategory_id: url_query_params.get("subcategory_id"),
                    year: url_query_params.get("year"),
                    month: url_query_params.get("month")
                };
                objectUtility.excludeEmptyKeys(query_params);
                router.push({name: "blog", query: query_params});
            }

            function moveToNextArticles() {
                const query_params = {
                    page: current_page + 1,
                    category_id: url_query_params.get("category_id"),
                    subcategory_id: url_query_params.get("subcategory_id"),
                    year: url_query_params.get("year"),
                    month: url_query_params.get("month")
                };
                objectUtility.excludeEmptyKeys(query_params);
                router.push({name: "blog", query: query_params});
            }

            function isFirstPage() {
                return current_page === 1;
            }

            function isLastPage() {
                return current_page === getMaxPage(filtered_article_count);
            }

            function getCategoryName(subcategory_id) {
                let category = null;
                const subcategory = subcategories.value.find(subcategory => subcategory.id === subcategory_id);
                if (subcategory) {
                    category = categories.value.find(category => category.id === subcategory.category_id);
                }
                return category ? category.name : null;
            }

            function getSubcategoryName(subcategory_id) {
                const subcategory = subcategories.value.find(subcategory => subcategory.id === subcategory_id);
                return subcategory ? subcategory.name : null;
            }

            //-----------------------------------------
            // メソッド（APIコールあり・テンプレート内使用なし）
            //-----------------------------------------
            function getArticles() {
                const query_params = {
                    offset: (current_page - 1) * blogConfig.DISPLAY_ARTICLE_NUM,
                    limit: blogConfig.DISPLAY_ARTICLE_NUM,
                    category_id: url_query_params.get("category_id"),
                    subcategory_id: url_query_params.get("subcategory_id"),
                    year: url_query_params.get("year"),
                    month: url_query_params.get("month"),
                }
                axios.get(`${httpUtility.getBackendAPIUrl(blogPathConfig.ARTICLE_API_PATH)}`, {params: query_params})
                .then(response => {
                    articles.value = response.data["articles"];
                    filtered_article_count = response.data["filtered_article_count"];
                    sortByCreatedTime(articles.value);
                })
                .catch(() => {
                    router.push({name: "error"});
                });
            }

            function getCategories() {
                axios.get(`${httpUtility.getBackendAPIUrl(blogPathConfig.CATEGORY_API_PATH)}`)
                .then(response => {
                    categories.value = response.data["categories"];
                    sortBySort(categories.value);
                })
                .catch(() => {
                    router.push({name: "error"});
                });
            }

            function getSubcategories() {
                axios.get(`${httpUtility.getBackendAPIUrl(blogPathConfig.SUBCATEGORY_API_PATH)}`)
                .then(response => {
                    subcategories.value = response.data["subcategories"];
                    sortBySort(subcategories.value);
                })
                .catch(() => {
                    router.push({name: "error"});
                });
            }

            function getYearmonths() {
                axios.get(`${httpUtility.getBackendAPIUrl(blogPathConfig.YEARMONTH_API_PATH)}`)
                .then(response => {
                    yearmonths.value = response.data["yearmonths"];
                    sortByYearmonth(yearmonths.value);
                })
                .catch(() => {
                    router.push({name: "error"});
                });
            }

            // -----------------------------------------
            // テンプレートへの返り値
            // -----------------------------------------
            return {
                getDateString: datetimeUtility.getDateString,
                articles, categories, subcategories, yearmonths,
                moveToArticle, moveToPrevArticles, moveToNextArticles, isFirstPage, isLastPage,
                getCategoryName, getSubcategoryName
                
            };
        }
    }
</script>

<style scoped lang="scss">
    @import "@/scss/views/blog/blog.scss";
</style>